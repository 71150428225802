import React from "react"

import Image from "~components/image/image"

const PartnerGrid = ({ images }) => {

	// console.log(images)
	return (<div className="partner_grid">
		
		{images !== undefined && images.filter(image => image && image?.asset).map(image => (<div key={image.asset.id} className="partner_square">
			<div className="partner_image">
				{image.asset && image.asset.gatsbyImageData && <Image image={image.asset.gatsbyImageData} />	}
			</div>
			
		</div>))}

	</div>)


}



export default PartnerGrid