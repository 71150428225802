import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

import { useHomePage } from "~queries/hooks"

import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleHeader from "~components/page/components/pageArticle/components/pageArticleHeader/pageArticleHeader"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import PageArticleFooter from "~components/page/components/pageArticle/components/pageArticleFooter/pageArticleFooter"
import PageArticleLink from "~components/page/components/pageArticle/components/pageArticleLink/pageArticleLink"

import PartnersCarousel from "./PartnersCarousel"

import "./partner-slideshow.scss"



const PartnersSlideshow = ({ location }) => {
  const { partnersSlideshow } = useHomePage()

  const pageTitle = 'Partners'

  return (
  	 <PageArticle title={pageTitle} className="partners_article">
      {partnersSlideshow && partnersSlideshow.slides.length > 0 ? <PageArticleHeader header={pageTitle} /> : null}
      <PageArticleSection className="partners">
        <Link to="/partners">
          {partnersSlideshow && partnersSlideshow.slides.length > 0 ? (
            <PartnersCarousel slideshow={partnersSlideshow} />  
          ) : null}
        </Link>
      </PageArticleSection>
      { partnersSlideshow && partnersSlideshow.slides.length > 0 ?
        <PageArticleFooter>
          <PageArticleLink to="/partners" copy={'See All'} />
        </PageArticleFooter> : null 
      }
    </PageArticle>
  )

}

export default PartnersSlideshow