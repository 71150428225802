import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Hook(s) */
import { usePodcasts, useHomePage } from "~queries/hooks"

/* Import Global Component(s) */
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleHeader from "~components/page/components/pageArticle/components/pageArticleHeader/pageArticleHeader"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import PageArticleFooter from "~components/page/components/pageArticle/components/pageArticleFooter/pageArticleFooter"
import PageArticleLink from "~components/page/components/pageArticle/components/pageArticleLink/pageArticleLink"
import PodcastCard from "~components/pages/podcast/components/podcastCard/podcastCard"
import Slideshow from "~components/slideshow/slideshow"

/* Import Local Style(s) */
import "./podcasts-article.scss"

const PodcastsArticle = ({ location }) => {
  const { podcastSlideshow, podcastLink } = useHomePage()
  const podcastsByYear = usePodcasts()
  const podcasts = podcastsByYear[0].posts
  const {
    episode,
    title,
    color: {
      color: { hex },
    },
    publicationDate,
  } = podcasts[0]

  return (
    <PageArticle title="Latest Podcast" className="podcasts">
      <PageArticleHeader header="Latest Podcast" />
      <PageArticleSection className="podcasts">
        <Link to="/podcast">
          {podcastSlideshow.images.length > 0 ? (
            <Slideshow slideshow={podcastSlideshow} />
          ) : null}
        </Link>
        <PodcastCard
          episode={episode}
          backgroundColor={hex}
          title={title}
          date={publicationDate}
        />
      </PageArticleSection>
      <PageArticleFooter>
        <PageArticleLink to="/podcast" copy={podcastLink} />
      </PageArticleFooter>
    </PageArticle>
  )
}

export default PodcastsArticle
