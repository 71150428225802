import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Hook(s) */
import { useVisitPage, useHomePage } from "~queries/hooks"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleHeader from "~components/page/components/pageArticle/components/pageArticleHeader/pageArticleHeader"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import PageArticleFooter from "~components/page/components/pageArticle/components/pageArticleFooter/pageArticleFooter"
import PageArticleLink from "~components/page/components/pageArticle/components/pageArticleLink/pageArticleLink"
import InformationList from "~components/pages/visit/components/informationList/informationList"
import Slideshow from "~components/slideshow/slideshow"

import FairGuideLink from "~components/pages/index/components/fairGuideLink/fairGuideLink"

/* Import Local Style(s) */
import "./visit-article.scss"

const VisitArticle = ({ location, displayVisitCopy, displayVisitInfo }) => {
  const { visitSlideshow, _rawVisitCopy, visitLink, displayHotel, hotelButton } = useHomePage()
  const { pageTitle, visitInformations } = useVisitPage()

  return (
    <PageArticle title={pageTitle} className="visit">
      <PageArticleHeader header={pageTitle} />
      <PageArticleSection className="visit">
        <Link to="/visit">
          {visitSlideshow.images.length > 0 ? (
            <Slideshow slideshow={visitSlideshow} />
          ) : null}
        </Link>
        {displayVisitCopy && <div className="font-size--small">
          <BlockContent blocks={_rawVisitCopy} />
        </div>}
        {displayVisitInfo && <InformationList information={visitInformations} />}
      </PageArticleSection>
      <PageArticleFooter>
        <PageArticleLink to="/visit" copy={visitLink} />

        {displayHotel && hotelButton.title && <FairGuideLink title={hotelButton.title} hex={hotelButton.bcolor.hex} to={'/travel'} /> }

      </PageArticleFooter>
    </PageArticle>
  )
}

export default VisitArticle
