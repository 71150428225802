import React from "react"

/* Import Global Components */
import BlockContent from "~components/blockContent/blockContent"

/* Import Local Style(s) */
import "./information-list.scss"

const InformationList = ({ information }) => (
  <ul className="information-list">
    {information.map(({ _key, title, _rawCopy }) => (
      <li className="information-list__information-item" key={_key}>
        <div className="information-list__information-item__title">
          <p>{title}:</p>
        </div>
        <div className="information-list__information-item__value">
          <BlockContent blocks={_rawCopy} />
        </div>
      </li>
    ))}
  </ul>
)

export default InformationList
