import React from "react"
import { parseISO } from "date-fns"
import { format } from "date-fns-tz"
import slug from "slug"

/* Import Global Component(s) */
import Card from "~components/card/card"

/* Import Local Style(s) */
import "./podcast-card.scss"

const PodcastCard = ({
  episode,
  title,
  date,
  backgroundColor = "var(--color-red)",
}) => (
  <Card
    className="podcast"
    backgroundColor={backgroundColor}
    to={`/podcast/podcast/${slug(title)}`}
  >
    <div className="row">
      <p className="episode">Episode {episode}</p>
    </div>
    <div className="row">
      <p className="title">{title}</p>
    </div>
    <div className="row">
      <p className="date">{format(parseISO(date), "d MMM yyyy")}</p>
      <p className="listen">Listen</p>
    </div>
  </Card>
)

export default PodcastCard
