import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Hook(s) */
import { useHomePage, useNewsPage, useNewsPosts } from "~queries/hooks"

/* Import Global Component(s) */
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleHeader from "~components/page/components/pageArticle/components/pageArticleHeader/pageArticleHeader"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import PageArticleFooter from "~components/page/components/pageArticle/components/pageArticleFooter/pageArticleFooter"
import PageArticleLink from "~components/page/components/pageArticle/components/pageArticleLink/pageArticleLink"
import NewsItemCard from "~components/pages/news/components/newsItemCard/newsItemCard"
import Slideshow from "~components/slideshow/slideshow"

/* Import Local Style(s) */
import "./news-article.scss"

const NewsArticle = ({ location }) => {
  const { newsItems, newsSlideshow, newsLink } = useHomePage()
  const { pageTitle } = useNewsPage()
  const newsPosts = useNewsPosts()

  return (
    <PageArticle title={pageTitle} className="news">
      <PageArticleHeader header={pageTitle} />
      <PageArticleSection className="news">
        <Link to="/news">
          {newsSlideshow.images.length > 0 ? (
            <Slideshow slideshow={newsSlideshow} />
          ) : null}
        </Link>
        {newsPosts[0].posts
          .slice(0, newsItems)
          .map(({ _id, publicationDate, title, externalLink, source }) => (
            <NewsItemCard date={publicationDate} title={title} source={source} externalLink={externalLink} key={_id} />
          ))}
      </PageArticleSection>
      <PageArticleFooter>
        <PageArticleLink to="/news" copy={newsLink} />
      </PageArticleFooter>
    </PageArticle>
  )
}

export default NewsArticle
