import React from "react"
import Card from "~components/card/card"


/* Import Local Style(s) */
import "./fair-guide-link.scss"

const FairGuideLink = ({hex, title, to }) => {
  
  return (
    <div className="fair-guide">
      <Card 
        backgroundColor={hex}
        to={to ? to : '/fair-guide'}
      >
        <p className="font-size--small">{title}</p>
      </Card>
    </div>
  )
}

export default FairGuideLink
