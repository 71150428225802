import React from "react"

import Card from "~components/card/card"

/* Import Local Style(s) */
import "./tickets-link.scss"

/*
<a
    href="https://www.squadup.com/hosts/5385987/events"
    className="tickets"
    target="_blank"
    rel="noreferrer"
  >

*/

const LogInLink = () => (
  <div className="tickets">
    <Card 
      backgroundColor="var(--color-yellow)"
      to={'/tickets'}
    >
      <p className="font-size--small">Buy Tickets Now</p>
    </Card>
  </div>
  
)

export default LogInLink
