import React from "react"
import { parseISO } from "date-fns"
import { format } from "date-fns-tz"
import slug from "slug"

/* Import Global Component(s) */
import Card from "~components/card/card"

/* Import Local Style(s) */
import "./news-item-card.scss"

const NewsItemCard = ({ date, title, externalLink, source }) => { 

  const isExternal = (externalLink !== undefined && externalLink !== null) ? true : false
  const targetUrl = isExternal ? externalLink : `/news/post/${slug(title)}`


  return title !== undefined ? (
  <Card
    className="news-item"
    backgroundColor="var(--color-lightest-gray)"
    to={targetUrl}
    key={slug(title)}
  >
    <p className="date">{format(parseISO(date), "d MMMM yyyy")}</p>
    <p className="title">{title}</p>
    <p></p>
    <p className="date">
      { (source !== undefined && source !== null) ? source : 'Press Release'}
    </p>
  </Card>
): null }

export default NewsItemCard
