import React, { useContext } from "react"
import { Fade } from "react-slideshow-image"

/* Import Global Context(s) */
import IntroContext from "~context/intro"

/* Import Global Component(s) */
import Image from "~components/image/image"

/* Import SLideshow Style(s) */
import "react-slideshow-image/dist/styles.css"

/* Import Local Style(s) */
import PartnerGrid from './PartnerGrid'
import "./partner-slideshow.scss"

const PartnersCarousel = ({ slideshow }) => {

  if(slideshow === undefined){
    return ''
  }

  const { slides, delay } = slideshow

  const [isIntroDone] = useContext(IntroContext)
  const slidesCount = slides.length

  return slidesCount > 1 ? (
    <div className="partner_slideshow">
      <Fade
        duration={delay * 1000}
        transitionDuration={150}
        arrows={false}
        pauseOnHover={false}
        canSwipe={false}
        autoplay={isIntroDone}
      >
        {slides.filter(s => s.images && s.images.length > 0).map((slide, index) => (
          <div className="each-fade" key={'slide' + index}>
            <div className="image-container">

              {(slide.images.length > 1) ? (<PartnerGrid images={slide.images} />) : (<Image image={slide.images[0].asset.gatsbyImageData} />) }

            </div>
          </div>
        ))}
      </Fade>
    </div>
  ) : (slides[0] !== undefined && slides[0] !== null) ? (
    <div className="partner_slideshow single-image">
      
      {slides[0].images.length > 1 ? <PartnerGrid images={slides[0].images} /> : <Image image={slides[0].images[0].asset.gatsbyImageData} />}
      
    </div>
  ) : ''
}

export default PartnersCarousel
